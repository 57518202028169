<template>
  <div class="card d-flex flex-column p-3 legal">
    <div class="d-flex justify-content-between align-items-start">
      <p class="legal_role m-0">{{ formattedRole }}</p>
      <div class="d-flex flex-column justify-content-start" style="height: 40px;">
        <span
          class="material-symbols-rounded legal_edit"
          @click="$emit('editMember')"
          >border_color</span
        >
        <span
          class="material-symbols-rounded legal_delete mt-3"
          @click="$emit('removeMember')"
          >delete</span
        >
      </div>
    </div>
    <p class="legal_name">{{ name }}</p>
  </div>
</template>

<script>
export default {
  props: {
    role: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isLegalTeam: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedRole() {
      return this.isLegalTeam ? `Adverse ${this.role}` : this.role;
    },
  },
};
</script>

<style scoped>
.legal {
  background-color: var(--light-green) !important;
  width: 264px;
  height: 160px;
  border-radius: 8px !important;
}
.legal_role {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: var(--dark-grey) !important;
}
.legal_name {
  cursor: default;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}
.legal_edit, .legal_delete {
    position: relative;
    z-index: 1;
}

.legal_edit {
  font-size: 18px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  color: var(--primary);
  &:hover{
    background-color: #E7ECF366;
    font-variation-settings: 'FILL' 22px !important;
  }
}
.legal_delete {
  pointer-events: auto;
  font-size: 18px;
  width: 18px;
  height: 18px;
  color: var(--status-red);
  &:hover{
    cursor: pointer;
  }
}
</style>
