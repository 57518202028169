<template>
  <div>
    <h4>Facts of the Case</h4>
    <!-- <div :class="{'border_error': isSubmitting}"> -->
    <div :class="{'border_error': isSubmitting && pre_event.length === 0}">
    <div class="subtitle">
      <h5>
        Case Info<span id="FOTC" class="sm">*</span>
        <b-tooltip target="FOTC" triggers="hover">
          All uploaded files must not exceed a combined total of 100MB.
        </b-tooltip>
      </h5>
      <LabelSwitch
        tag="FOTC"
        :label="['Upload File', 'Input Manually']"
        :def="state"
        @switch="changeState"
      />
    </div>

    <div class="input-container">
      <div v-show="state == 0">
        <FileInput class="input" :accept="['pdf','docx','png','jpg','jpeg']" @input-files="files = $event"/>
        <FileProcess class="process" :accept="['pdf','docx','png','jpg','jpeg']" :files="files" @output-files="pre_event = $event"/>
      </div>
      <div v-show="state == 1">
        <textarea
          class="form-control"
          v-validate="'required'"
          name="Facts of the case"
          placeholder="Input relevant information about the case"
          rows="5"
          @input="pre_event = $event.target.value"
          :key="resetKey"
        ></textarea>
        <span class="material-symbols-rounded" @click="clearTextarea">delete</span>
      </div>
    </div>
    </div>

    <b-modal
      id="FOTC-change-state"
      hide-header
      hide-footer
      centered
    >
      <div class="delete-prompt">
        <p>
          <b>Are you sure you want to switch input method?</b><br>
          All unsaved data will be lost
        </p>
      </div>
      <div class="delete-prompt-options">
        <button class="btn-outlined" @click="manipState(false)">
          Keep Editing
        </button>
        <button class="btn-danger" @click="manipState(true)">Switch and Delete</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import LabelSwitch from '../../../components/input/LabelSwitch.vue';
import FileInput from '../../../components/input/FileInput.vue';
import FileProcess from '../../../components/input/FileProcess.vue';

export default {
  props: {
    isSubmitting: Boolean,
    shouldReset: Boolean,
  },
  components: {
    LabelSwitch,
    FileInput,
    FileProcess,
  },

  data() { return {
    // this input is corresponded to the `pre_event` field in API payload
    pre_event: [],

    /**
     * toggle between display state/input state
     * [0] upload file
     * [1] manual input
     */
    state: 0,
    resetKey: 0,

    // store all file inputs
    files: [],
  }},

  watch: { 
    pre_event() { this.$emit("input", this.pre_event); },
    shouldReset(newVal) {
      if (newVal) {
        this.resetValues();
      }
    } 
    },

  methods: {
    clearTextarea() {
      document.getElementsByName("Facts of the case")[0].value = "";
      this.pre_event = "";
    },

    changeState(e) {
      if (e !== this.state) this.$bvModal.show("FOTC-change-state");
      document.getElementsByName("FOTC-switch")[this.state ? 1 : 0].click();
    },

    manipState(flag) {
      if (flag) {
        this.state = this.state ? 0 : 1;
        document.getElementsByName("FOTC-switch")[this.state].click();

        this.clearTextarea();
        this.files = [];
      }

      this.$bvModal.hide("FOTC-change-state");
    },
     resetValues() {
      this.pre_event = "";
      this.files=[];
      this.resetKey += 1;
      this.$emit("input", this.pre_event);
    },
  }
}
</script>

<style scoped>
.form-control {
  font-size: 14px;
  resize: none;
}

h4 {
  margin: 32px 0px;
}

p {
  margin-bottom: 0px;
}

.subtitle {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    margin: 0px;
    display: flex;
    align-items: center;

    span {
      margin-left: 2px;
      color: var(--neutral-black);

      &:hover {
        font-variation-settings: 'FILL' 1;
      }
    }
  }

  span {
    font-size: 18px;
    color: var(--primary);
    cursor: pointer;
  }
}

.text-disp {
  display: flex;

  p {
    width: 100%;
    padding: 20px;
    border: 1px solid var(--light-grey);
    border-radius: 8px;
    background-color: var(--interface-grey);
  }

  span {
    margin: 0px 0px auto 22px;
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    color: var(--status-red);
    cursor: pointer;
  }
}
</style>

<!-- Input Container -->
<style scoped>
.input-container {
  margin-bottom: 32px;

  > div {
    width: 100%;
    display: flex;
  }

  .input {
    width: 55%;
    margin-right: 20px;
  }

  .process {
    width: calc(45% - 20px);
    max-height: 200px;
    overflow-y: auto;
  }

  textarea {
    padding: 12px;
  }

  span {
    margin: 0px 0px auto 22px; 
    font-size: 18px;
    color: var(--status-red);
    cursor: pointer;
  }
}
</style>

<!-- Delete Modal -->
<style scoped>
.delete-prompt {
  margin: 8px 8px 20px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-prompt p {
  margin-bottom: 0px;
  text-align: center;
}

.delete-prompt p b {
  font-weight: 500;
  font-size: 16px;
  color: var(--neutral-black);
}

.delete-prompt-options {
  display: flex;
  justify-content: space-between;
  margin: 0px 8px 8px 8px;
}

.delete-prompt-options button {
  width: calc(50% - 4px);
  height: 32px;
}
.sm{
  color: var(--status-red) !important;
}
.border_error{
  outline: 1px solid var(--status-red);
  border-radius: 8px;
}
</style>