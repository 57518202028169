<template>
  <div class="alert alert-success alert-dismissible fade show" role="alert">
    {{ message }}
    <button type="button" class="btn-close" @click="$emit('closeAlert')"></button>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.alert {
  margin-bottom: 20px;
}
</style>
