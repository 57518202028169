<template>
  <div>
    <div
      v-for="(obj, id) in selected"
      :key="`juris-court-entry-${id}`"
      class="entry"
    >
      <p>{{ id + 1 }}.</p>

      <JurisdictionSelect
        :options="processedOptions[id]"
        :reset="obj.j"
        @input="obj.j = $event"
      />

      <div
          :class="['court', obj.j && 'active', {'choose_error': obj.j && obj.c.length === 0}]"
          @click="showCourtModal(id, obj)"
        >
        <p v-if="!obj.c.length">{{ obj.j ? "Choose the appropriate court for this case" : "Please Select a Jurisdiction"}}</p>

        <span
          v-else v-for="(c, c_id) in obj.c"
          :key="`main-court-${c_id}`"
          class="court-badge"
        >
          <!-- <p>{{ c.name }}</p> -->
          <p class="court-name">{{ truncateName(c?.name ?? "All Courts & Institutions") }}</p>
          <span class="material-symbols-rounded" @click="removeCourt(id, c_id)">close</span>
        </span>

        <span class="material-symbols-rounded fill">filter_alt</span>
      </div>

      <span class="material-symbols-rounded" @click="deleteEntry(id)">delete</span>
    </div>

    <button v-if="selected.length < Object.keys(options).length" class="small-button btn-transparent" @click="addEntry()">
      <span class="material-symbols-rounded">add</span>
      Add more
    </button>

    <CourtSelect
      name="PretrialResearchCourt"
      :config="courtModalConfig"
      @input="selected[current].c = $event"
    />
  </div>
</template>

<script>
import country from '../../assets/country';
import JurisdictionSelect from './JurisdictionSelect.vue';
import CourtSelect from './CourtSelect.vue'
export default {
  components: {
    JurisdictionSelect,
    CourtSelect,
  },

  props: {
    options: {
      type: Object,
      default: () => country,
    },
  },

  data() { return {
    courtModalConfig: {},
    showModal: true,

    current: null,
    selected: [{ j: null, c: [], }],
  }},

  computed: {
    processedOptions() {
      const exclude = this.selected.map(o => o.j).filter(j => !!j);
      return this.selected.map(o => Object.fromEntries(Object
        .entries(this.options)
        .filter(op => !exclude.includes(op[0]) || op[0] === o.j)
      ));
    },
  },
 watch: {
  selected: {
    handler() {
      this.updateSelected();
    },
    deep: true,
  },
},
  methods: {
    addEntry() {
      if (this.selected.length === this.options.length) return;
      this.selected.push({ j: null, c: [], });
    },

    deleteEntry(id) {
      if (this.selected.length > 1) this.selected.splice(id, 1);
      else this.selected = [{ j: null, c: [], }];
    },

    updateSelected() {
      this.$emit("update", this.selected);
    },

    showCourtModal(id, obj) {
      if (!this.showModal || !obj.j) return;
      
      this.current = id;
      this.courtModalConfig = {...obj};
      this.$bvModal.show("PretrialResearchCourt");

      this.showModal = true;
    },

    removeCourt(id, c_id) {
      this.selected[id].c.splice(c_id, 1);
      this.showModal = false;
    },
    truncateName(name) {
      const maxLength = 50; 
      if (name.length > maxLength) {
        return name.substring(0, maxLength) + '...';
      }
      return name;
    },
  },
}
</script>

<style scoped>
p {
  margin: 0px;
}

.entry {
  margin-bottom: 10px;
  display: flex;

  > p {
    width: 12px;
    margin: 8px 10px 8px 0px;
  }

  > span {
    margin: auto 10px auto 6px;
    width: 22px;
    height: 22px;
    font-size: 18px;
    color: var(--status-red);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.court {
  margin-left: 10px;
  padding: 4px 10px;
  min-height: 40px;
  border-radius: 6px;
  border: 1px solid var(--light-grey);
  background-color: var(--interface-grey);
  display: flex;
  flex-grow: 1;
  align-items: center;
  cursor: not-allowed;

  &.active {
    cursor: pointer;
  }

  &.active:hover {
    background-color: var(--light-grey);
    border-color: var(--mid-grey);
  }

  > p {
    color: var(--dark-grey) !important;
  }

  .material-symbols-rounded.fill {
    margin: 0px 6px 0px auto;
    width: 22px;
    height: 22px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* .court-badge {
  margin-right: 6px;
  padding: 0px 12px;
  min-height: 32px;
  border-radius: 16px;
  background-color: var(--mid-grey);
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin-left: 8px;
    font-size: 18px;
  }
} */
 .court-badge {
  margin-right: 6px;
  padding: 0px 12px;
  min-height: 32px;
  width: 270px;
  border-radius: 16px;
  background-color: var(--mid-grey);
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .court-name {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    margin-left: 8px;
    font-size: 18px;
  }
}

.small-button {
  margin: 32px auto 0px auto;
  padding: 0px 16px;
  height: 32px;

  span {
    margin-right: 8px;
    font-size: 22px;
  }
}
.choose_error{
  outline: 1px solid var(--status-red);
  background: #FF4D4F1A;
}
</style>