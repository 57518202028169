<template>
  <b-modal
    :id="name" centered
    custom-class="court-modal"
    hide-header hide-footer
    size="lg"
    v-if="COURTS"
  >
    <div id="header">
      <h4>Select Jurisdiction</h4>
      <span class="material-symbols-rounded" @click="$bvModal.hide(name)">close</span>
    </div>

    <div id="tabs">
      <span
        v-for="(tab, id) in Object.keys(COURTS.courts)"
        :key="`court-tab-${id}`"
        :class="selectedTab[1] === id && 'active'"
        @click="switchTab([tab, id])"
      >
        {{ tab }}
        {{
          ALL ? "(All)"
          : tabCount[id] ? `(${tabCount[id]})`
          : ""
        }}
      </span>
    </div>

    <label id="SearchCourt">
      <span class="material-symbols-rounded">search</span>
      <input name="Search Court" placeholder="Search Court" v-model.trim="search"/>
    </label>

    <div id="SelectAll">
      <CheckBox :set="selectedCourt[0] === 'all'" @input="toggleCourt($event, 'all')" />
      <span id="AllInfo" class="material-symbols-rounded info">info</span>
      <b-tooltip target="AllInfo" triggers="hover">
        Select all courts and institutions (choose if you're unsure which specific court to select)
      </b-tooltip>
      <p>Select All Courts and Institutions</p>
    </div>

    <div>
      <div id="States">
        <h4>
          {{ tabTitle(selectedTab[0]) }}
          {{ tabCourts.length ? `(${tabCourts.length})` : "" }}
        </h4>
        <template v-if="selectedTab[0] !== 'Others'">
          <div
            v-for="(st, s_id) in COURTS.states"
            :key="`state-${s_id}`"
            :class="['states', selectedState === s_id && 'active']"
            @click="selectedState = s_id"
          >
            <p>
              {{ st.split("|")[0] }}
              {{ stateCount(st) ? `(${stateCount(st)})` : "" }}
            </p>
            <span class="material-symbols-rounded">chevron_right</span>
          </div>
        </template>
      </div>

      <div id="Courts">
        <div class="states extended">
          <h4>
            {{
              selectedTab[0] === "Others"
                ? "Others"
                : COURTS.states[selectedState].split("|")[0]
            }}
          </h4>
          <button
            id="TypeFilter"
            class="btn-transparent"
            aria-expanded="false"
          >
            Filter by Court Type ({{
              ![0, selectedTypeFilter.length].includes(typeFilter.length)
                ? typeFilter.length
                : "All"
            }})
            <span class="material-symbols-rounded arrow">expand_more</span>
          </button>
          
          <b-tooltip
            target="TypeFilter"
            triggers="click"
            placement="bottom"
            id="LESFilterDropdown"
            :custom-class="`DROPDOWN-MENU ${config.j}`"
          >
            <div
              v-for="(option, o_id) in Object.keys(processedCourts)"
              :key="`dropdown-option-${o_id}`"
              class="dropdown-option"
              @click="toggleTypeFilter($event, o_id)"
            >
              <CheckBox :set="selectedTypeFilter[o_id]" />
              <p>{{ option }}</p>
            </div>
          </b-tooltip>
        </div>

        <div
          v-for="(ct, type) in filteredCourt"
          :key="`${type}-list`"
          class="court-group"
        >
          <template v-if="ct.length > 0">
            <h5>{{ type }}</h5>
            <div
              v-for="(c, c_id) in ct"
              :key="`listed-court-${c_id}`"
            >
              <CheckBox v-if="ALL" :set="ALL" :force="true" @input="deselectAll" />

              <template v-else>
                <CheckBox
                  v-show="(selectedCourt.map(i=>i.id).includes(c.id) || selectedCourt.length < 2)"
                  :set="selectedCourt.map(i=>i.id).includes(c.id)"
                  @input="toggleCourt($event, c)"
                />

                <CheckBox v-show="!(selectedCourt.map(i=>i.id).includes(c.id) || selectedCourt.length < 2)" :disabled="true" v-b-tooltip.bottom="'You’ve reached the maximum limit of 2 courts. To select a different court, please deselect one of your current choices.'"/>
              </template>

              <p>{{ c.name }}</p>
            </div>
          </template>
        </div>
      </div>

      <div id="section-break"></div>
    </div>
    
    <button id="footer" class="btn-primary" @click="applySelection">Apply</button>


    <!-- alert modal -->
    <b-modal
      :id="`alert-modal`"
      custom-class="delete-modal"
      hide-header
      hide-footer
      centered
    >
      <div class="delete-prompt">
        <p>
          <b>Deselecting a court will reset all your selections.</b><br>
          The limit is 2 courts. Do you want to continue?
        </p>
      </div>
      <div class="delete-prompt-options">
        <button class="btn-outlined" @click="$bvModal.hide(`alert-modal`)">
          Cancel
        </button>
        <button class="btn-danger" @click="deselectAll('deselect')">Continue</button>
      </div>
    </b-modal>
  </b-modal>
</template>

<script>
import courtname from '../../store/courtname';
import CheckBox from './CheckBox.vue';

export default {
  components: {
    CheckBox,
  },

  props: {
    name: {},
    config: {},
  },

  data() { return {
    search: "",

    previousTab: 0,
    selectedTab: [null, 0],
    previousState: 0,
    selectedState: 0,
    processedCourts: {},

    selectedTypeFilter: [],
    selectedCourt: [],
  }},

  computed: {
    COURTS () { return courtname[this.config.j]; },

    typeFilter() { return this.selectedTypeFilter.filter(f => f); },
    filteredCourt() {
      if (!this.COURTS?.states ?? true) return;

      var Filtered = Object.entries(this.processedCourts);

      // search query filter
      if (this.search.length) {
        const regexp = new RegExp(`(${this.search.toLowerCase()})`);
        Filtered = Filtered.filter((ent, id) => {
          Filtered[id][1] = ent[1].filter(c => c.name.toLowerCase().match(regexp));
          return Filtered[id][1].length > 0;
        });
      }

      // states filter
      if (this.selectedTab[0] !== "Others") {
        const reStr = this.COURTS.states[this.selectedState];
        const regexp = new RegExp(`(${reStr})`, "i");

        Filtered = Filtered.filter((ent, id) =>{
          Filtered[id][1] = ent[1].filter(c => {
            // specify additional rules here //

            // courts in WV will be put in Virginia without this part of codes
            // which is not correct
            if (
              !reStr.includes("West Virginia") &&
              c.name.includes("West Virginia")
            ) return false;
            
            return c.name.match(regexp);
          });
          return Filtered[id][1].length > 0;
        });
      }

      // court type filter
      if (this.typeFilter.length) {
        Filtered = Filtered
          .filter(h =>
            (this.selectedTypeFilter[
              Object.keys(this.processedCourts).findIndex(i => i === h[0])
            ] ?? false)
          );
      }

      return Object.fromEntries(Filtered);
    },

    ALL() { return this.selectedCourt[0] === "all"; },
    tabCount() {
      return Object.values(this.COURTS.courts).map(h => {
        var ct = 0;
        this.selectedCourt.forEach(c => {
          if (h.map(i => i.id).includes(c.id)) ct++;
        });
        return ct;
      });
    },
    tabCourts() {
      const flatArray = [];
      Object.values(this.processedCourts).forEach(c => flatArray.push(...c));
      return flatArray.filter(i => this.selectedCourt.map(c => c.id).includes(i.id));
    },
  },

  watch: {
    config: {
      handler(newVal) {
        this.selectedCourt = [...newVal.c];
      },
      deep: true,
    },

    COURTS(newVal) {
      this.selectedTab = [Object.keys(newVal.courts)[0], 0];
      this.processCourts();
    },

    selectedTab: {
      handler() {
        this.processCourts();
        setTimeout(() => this.previousTab = this.selectedTab[1], 0);
      },
      deep: true,
    },

    selectedState() {
      setTimeout(() => this.previousState = this.selectedState, 0);
    },
  },

  methods: {
    hideAllTooltip() { this.$root.$emit('bv::hide::tooltip'); },

    tabTitle(t) {
      const mapping = {
        "State Courts": "States",
        "Federal Courts": "Federal",
      };
      return mapping[t] ?? t.replace(" Jurisdiction", ""); 
    },

    stateCount(st) {
      return this.tabCourts
        .map(c => new RegExp(st).test(c.name))
        .filter(v => v).length;
    },

    switchTab(v) {
      this.hideAllTooltip();
      this.selectedTab = v;
    },

    processCourts() {
      var ALL = [...this.COURTS.courts[this.selectedTab[0]]];
      const courts = {};
      Object
        .entries(this.COURTS.hierarchy)
        .forEach(([k, v]) => {
          const regexp = new RegExp(`(${v})`, "i");
          courts[k] = ALL.filter(c => c.name.match(regexp) !== null);

          ALL = ALL.filter(c => !Object
            .values(courts)
            .reduce((a, crr) => a.concat(crr), [])
            .includes(c)
          );
        })

      courts["Others"] = ALL;
      this.processedCourts = courts;
    },

    toggleTypeFilter(e, o) {
      const tagName = e.target.tagName;
      if (["P", "DIV"].includes(tagName)) this.hideAllTooltip();
      if (["LABEL", "SPAN"].includes(tagName)) return;

      this.$set(this.selectedTypeFilter, o, !(this.selectedTypeFilter[o] ?? false));
    },
    
    deselectAll(e) {
      if (e === "deselect") {
        this.toggleCourt(false, "all");
        this.$bvModal.hide("alert-modal");
        return;
      }

      Array
        .from(document.getElementsByClassName("checkbox"))
        .forEach(e => {
          if (!e.children[0].checked) this.$bvModal.show("alert-modal");
          e.children[0].checked = true;
        });
    },

    toggleCourt(e, c) {
      if (
        this.previousState !== this.selectedState ||
        this.previousTab !== this.selectedTab[1]
      ) return;

      if (c === "all") this.selectedCourt = e ? ["all"] : [];

      else if (e) {
        if (!this.selectedCourt.map(i => i.id).includes(c.id)) {
          this.selectedCourt.push({...c});
        }
      } else if (!e) {
        const id = this.selectedCourt.findIndex(i => i.id === c.id);
        this.selectedCourt.splice(id, 1);
      }
      
      // setTimeout(() => this.$bvModal.hide("alert-modal"), 1000);
    },

    applySelection() {
      this.$emit("input", [...this.selectedCourt]);
      this.$bvModal.hide(this.name);
    },
  },
}
</script>

<style>
[custom-class=court-modal] .modal-body {
  padding: 0px;
}

#LESFilterDropdown {
  top: -20px !important;
  max-width: none !important;

  .tooltip-inner {
    max-width: none !important;
  }

  .dropdown-option {
    align-items: center;

    .checkbox {
      margin-right: 8px;

      span {
        margin: 0px;
      }
    }
  }
}

#LESFilterDropdown.USA {
  left: -30px !important;
}

#LESFilterDropdown.UK {
  left: 40px !important;
}
</style>

<style scoped>
p {
  margin: 0px;
}

#header {
  padding: 0px 24px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin-bottom: 0px;
  }

  span {
    width: 36px;
    height: 36px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

#tabs {
  border-bottom: 1px solid var(--light-grey);
}

#tabs span {
  padding: 0px 32px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &.active {
    border-bottom: 2px inset var(--primary);
  }
}

#SearchCourt {
  margin: 12px 24px 16px 24px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid lightgrey;
  background-color: var(--interface-grey);
  display: flex;
  align-items: center;

  span {
    margin: auto 8px auto 16px;
    font-size: 24px;
    color: var(--dark-grey);
  }

  input {
    margin-right: 10px;
    width: 100%;
    background-color: transparent;
  }
}

#SelectAll {
  margin: auto 24px 16px;
  height: 32px;
  display: flex;
  align-items: center;

  .info {
    font-size: 22px;
    margin: auto 2px auto 8px;
  }
}

div:has(#States) {
  position: relative;
  margin: 0px 24px;
  border-bottom: 1px solid var(--light-grey);
  display: flex;
}

#section-break {
  position: absolute;
  top: 0px;
  left: 214px;
  height: 100%;
  width: 2px;
  border-top: 2px solid white;
  background-color: #D9D9D9;
}

#footer {
  margin: 10px 16px 10px auto;
}
</style>

<!-- States / Regions -->
<style scoped>
#States {
  width: 220px;
  height: 50vh;
  overflow: auto;
  z-index: 1;

  h4 {
    margin-bottom: 12px;
  }

  &::-webkit-scrollbar-track {
    border-color: transparent;
    background-color: transparent;
  }
}

.states {
  padding: 0px 10px;
  width: 200px;
  min-height: 32px;
  margin-bottom: 8px;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  transition: all .2s ease;

  h4 {
    margin: 0px auto 0px 0px;
  }

  p {
    margin: 4px auto 4px 0px;
  }

  span:not(.arrow) {
    margin: auto 0px;
    display: none;
    font-size: 24px;
    color: var(--dark-grey);
    transition: all .2s ease;
  }

  &.active{
    background-color: var(--light-grey);
    span {
      display: block;
    }
  }

  &.extended {
    margin-bottom: 12px;
    width: 100%;

    .arrow {
      color: var(--primary);
    }
  }
}
</style>

<!-- Courts -->
<style scoped>
#Courts {
  padding-left: 15px;
  width: calc(100% - 220px);
  height: 50vh;
  overflow: auto;
}

.court-group {
  margin-left: 10px;

  h5 {
    margin: 0px 0px 8px;
    height: 32px;
    font-weight: 500 !important;
    display: flex;
    align-items: center;
  }

  > div {
    margin-bottom: 8px;
    min-height: 32px;
    display: flex;

    p {
      margin-top: 4px;
      width: calc(100% - 40px);
    }

    > label {
      margin: 6px 10px auto 16px;
    }
  }
}
</style>

<!-- Delete Modal -->
<style scoped>
.delete-prompt {
  margin: 8px 8px 20px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-prompt p {
  margin-bottom: 0px;
  text-align: center;
}

.delete-prompt p b {
  font-weight: 500;
  font-size: 16px;
  color: var(--neutral-black);
}

.delete-prompt-options {
  display: flex;
  justify-content: space-between;
  margin: 0px 8px 8px 8px;
}

.delete-prompt-options button {
  width: calc(50% - 4px);
  height: 32px;
}
</style>
