<template>
  <div class="modal" :id="modalId" tabindex="-1" :aria-labelledby="modalLabelId" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content pb-0">
        <div class="d-flex justify-content-between align-items-center modal_header">
          <h5 class="modal-title" :id="modalLabelId">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <p v-if="modalId === 'legalTeamModal'" class="adverse p-3 pb-0">Adverse</p>
        <div :class="modalId === 'legalTeamModal' ? 'pt-0 p-3' : 'p-3'">
          <div class="mb-3">
            <label :for="typeSelectId" class="form-label">Select Type</label>
            <div class="dropdown">
              <button
                class="btn dropdown-toggle w-100 form-select d-flex justify-content-between align-items-center"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span :class="{ 'default-style': !currentMember.role }">{{ currentMember.role || 'Type' }}</span>
                <i class="dropdown-icon"></i>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li v-for="option in options" :key="option">
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="currentMember.role = option"
                  >
                    {{ option }}
                  </a>
                </li>
              </ul>
            </div>
            <span v-if="showErrors && !currentMember.role" class="text-danger">Please select a type.</span>
          </div>

          <div v-for="(field, index) in fieldsArr" :key="index" class="mb-3">
            <label :for="field.id" class="form-label">{{ field.label }}</label>
            <input
              type="text"
              class="form-control"
              :id="field.id"
              :placeholder="field.placeholder"
              v-model="currentMember[field.model]"
            />
          </div>

          <span v-if="showErrors && !isAnyFieldFilled" class="text-danger">Please fill at least one name field.</span>
        </div>
        <div class="modal-footer mt-1">
          <button type="button" class="btn save_btn" @click="validateAndSave">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalId: String,
    modalLabelId: String,
    title: String,
    currentMember: Object,
    options: Array,
    fieldsArr: Array,
    saveMember: Function,
  },
  data() {
    return {
      typeSelectId: this.modalId + '-type',
      showErrors: false,
    };
  },
  computed: {
    isAnyFieldFilled() {
      return this.fieldsArr.some(field => this.currentMember[field.model]);
    }
  },
  methods: {
    validateAndSave() {
      this.showErrors = true;

      const isDropdownValid = this.currentMember.role;
      const isNameFieldFilled = this.isAnyFieldFilled;

      // Check validation conditions
      if (isDropdownValid && isNameFieldFilled) {
        this.saveMember();
        this.showErrors = false; 
      }
    }
  }
};
</script>

<style scoped>
.text-danger {
  color: red;
  font-size: 0.875rem;
}
</style>

<style scoped>
.adverse {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--primary) !important;
}

.btn-close {
  font-size: 13px !important;
  padding:25px;
  color: var(--neutral-black) !important;
}

.modal-dialog {
  max-width: 572px;
}

.form-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.save_btn {
  background: var(--primary);
  width: 72px;
  border-radius: 4px;
  color: #fff;
  border: none;
}

.modal_header {
  border-bottom: 1px solid #F0F0F0;
}

.modal-footer {
  border-top: 1px solid #F0F0F0;
}
.dropdown-item{
  width: 524px;
  min-height: 44px;
  padding: 12px 16px;
  color: #000 !important;
}
.dropdown-item:hover {
  background-color: var(--light-grey) !important;
}
.dropdown-menu{
width: 524px;
min-height: 240px;
border-radius: 8px;
box-shadow: 0px 4px 10px 0px #AAABAF99;
border: none;
}
.modal-dialog {
  max-width: 572px;
}
.form-label{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.form-select,
.form-control{
  background: var(--interface-grey) !important;
}
.form-select,
.form-control,li {
  border: none !important;
  width: 524px !important;
  height: 40px !important;
  border-radius: 8px !important;
}
.form-control::placeholder{
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.save_btn{
  background: var(--primary);
  width: 72px;
  border-radius: 4px;
  color: #fff;
  border: none;
}
.modal_header{
  border-bottom: 1px solid #F0F0F0;
}
.modal-footer{
  border-top: 1px solid #F0F0F0;
}
.default-style {
  font-size: 14px;
  color: var(--dark-grey);
}
.modal-title{
width: 572px;
min-height:60px;
padding: 16px 24px;
}
</style>
