<template>
  <div class="select" :aria-expanded="toggle" @click="toggle = !toggle">
    <p v-if="!selected" class="dark-grey">Country</p>
    <div v-else class="option selected">
      <img :src="options[selected]" :alt="selected" />
      <p>{{ selected }}</p>
    </div>

    <span class="material-symbols-rounded arrow">arrow_drop_down</span>

    <div class="dropdown">
      <div
        :class="['option', selected === j && 'active']"
        v-for="(i, j) in options"
        :key="`juris-option-${j}`"
        @click="select(j)"
      >
        <img :src="i" :alt="j" />
        <p>{{ j }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import country from '../../assets/country';

export default {
  props: {
    reset: {},

    options: {
      type: Object,
      default: () => country,
    },
  },

  data() { return {
    toggle: false,
    selected: null,
  }},

  watch: {
    reset(newVal) {
      this.toggle = false;
      this.selected = newVal;
    },

    options(newVal) {
      if (Object.keys(newVal).includes(this.selected)) return;
      this.selected = null;
      this.$emit("input", this.selected);
    },
  },

  methods: {
    select(j) {
      this.selected = j;
      this.$emit("input", this.selected);
    },
  }
}
</script>

<style scoped>
p {
  margin: 0px;
}

.dark-grey {
  color: var(--dark-grey) !important;
}

.select {
  position: relative;
  min-width: 120px;
  height: 40px;
  padding: 0px 8px 0px 12px;
  border-radius: 6px;
  border: 1px solid var(--light-grey);
  background-color: var(--interface-grey);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

*[aria-expanded=true] .dropdown {
  display: block;
}

.dropdown {
  display: none;
  position: absolute;
  top: 40px;
  left: 0px;
  width: 100%;
  border-radius: 6px;
  background-color: var(--interface-grey);
  box-shadow: 0px 4px 10px 0px #AAABAF99;
  z-index: 5;
}

.option {
  padding: 0px 12px 0px 12px;
  height: 40px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #E7ECF366;

    p {
      color: #0E448599 !important;
    }
  }

  img {
    margin-right: 10px;
    width: 18px;
    aspect-ratio: 1;
  }
}

.dropdown .option p {
  color: var(--dark-grey) !important;
}

.option.selected {
  padding: 0px;

  &:hover {
    background-color: transparent;
  }
}

.option.active, .option.active:hover {
  background-color: var(--light-blue);

  p {
    color: var(--primary) !important;
  }
}
</style>