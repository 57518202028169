<template>
  <div
    class="card h-100 d-flex align-items-center justify-content-center p-3 legal_add cursor-pointer"
    @click="$emit('click')"
  >
    <span class="material-symbols-rounded text-muted legal_icon">add</span>
    <span class="legal_text mt-3">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Add file'
    }
  }
}
</script>

<style scoped>
.legal_add {
  background: var(--interface-grey) !important;
  width: 264px !important;
  height: 160px !important;
  border-radius: 8px !important;
  border: 2px dashed var(--mid-grey) !important;
}
.legal_icon {
  border-radius: 50px;
  background: var(--light-grey);
  padding: 10px;
  color: var(--dark-grey) !important;
}
.legal_text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 2px 8px;
  border-radius: 4px;
  color: var(--primary) !important;
}
.add_card:hover .legal_icon {
  color: #fff !important;
  background-color: var(--dark-grey);
}
.add_card:hover .legal_text {
  background-color: var(--light-grey);
}
</style>
