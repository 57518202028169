<template>
  <div @click="hideAllDropdown">
    <!-- header -->
    <BreadCrumb title="Legal AI Trial Copilot" subtitle="Add New Case" />

    <!-- input -->
    <div class="container mt-4" style="max-width: 900px">
      <div class="card p-4">
        <h4 class="mb-4">Pre-trial Input</h4>

        <div class="border-btm" style="padding-bottom: 10px">
          <!-- Client Case Input -->
          <div :class="{ border_error: errors.has('clientCase') }" class="mb-4">
            <label for="clientCase" class="client_title mb-1">
              {{ "Client Case" }}<span class="sm">*</span>
            </label>
            <div class="position-relative">
              <textarea
                id="clientCase"
                name="clientCase"
                class="client_card form-control"
                rows="3"
                placeholder="Summarize your client’s case in one sentence. For example: Claiming defamation and false allegations of domestic violence against ex-wife."
                v-model.trim="clientCase"
                v-validate="'required'"
                maxlength="200"
                :class="{
                  border_error: getCharCount > 200,
                  background_error: getCharCount > 200,
                }"
              ></textarea>
              <div
                class="position-absolute end-0 bottom-0 p-3 text-muted small gw"
              >
                {{ getCharCount }} / 200
              </div>
            </div>
          </div>

          <!-- Client Type Selection -->
          <div
            :class="{ type_error: errors.has('clientType') }"
            class="mb-4 d-flex align-items-center"
          >
            <label class="client_title me-3 mb-0">
              {{ "Client Type:" }}<span class="sm">*</span>
            </label>
            <div class="custom-radio-wrapper">
              <!-- Custom Radio Button for Petitioner -->
              <input
                type="radio"
                id="petitioner"
                v-validate="'required'"
                name="clientType"
                value="Petitioner"
                v-model="clientType"
                class="custom-radio-input"
              />
              <label for="petitioner" class="custom-radio-label">
                <div class="custom-radio-circle"></div>
                <span class="custom-radio-text">Petitioner</span>
              </label>

              <!-- Custom Radio Button for Respondent -->
              <input
                type="radio"
                id="respondent"
                v-validate="'required'"
                name="clientType"
                value="Respondent"
                v-model="clientType"
                class="custom-radio-input"
              />
              <label for="respondent" class="custom-radio-label">
                <div class="custom-radio-circle"></div>
                <span class="custom-radio-text">Respondent</span>
              </label>
            </div>
          </div>
        </div>

        <!-- Main Jurisdiction and Court -->
        <JurisdictionAndCourt
          @input="JurisdictionCourts"
          @legal-input="legalTeam = $event"
          @judiciary-input="judiciary = $event"
          :isSubmitting="isSubmitting"
          :shouldReset="shouldReset"
        />

        <!-- Team Section -->
        <!-- <LegalTeamAndJudiciary /> -->

        <!-- Facts of the case -->
        <FactsOfTheCase
          class="border-btm"
          @input="$set(pre_event, 1, $event)"
          :isSubmitting="isSubmitting"
          :shouldReset="shouldReset"
        />

        <WitnessInput
          class="border-btm"
          @c-input="c_wit = $event"
          @a-input="a_wit = $event"
          :shouldReset="shouldReset"
        />

        <EvidenceInput
          @c-input="c_evid = $event"
          @a-input="a_evid = $event"
          :shouldReset="shouldReset"
        />
        <!-- Save Button -->
        <div class="mt-3" id="Error" v-if="isSubmitting">
          <p>
            <span class="material-symbols-rounded icon_info">Error</span>
            Please complete all required<span class="sm">*</span> fields before
            continuing.
          </p>
        </div>

        <div style="display: flex; justify-content: flex-end">
          <button @click="$bvModal.show('cancel')" class="btn cancel me-3 mt-4">
            {{ "Cancel" }}
          </button>
          <button
            :disabled="!isAllFieldsValid"
            @click="sendValue"
            :class="{
              'next-disabled': !isAllFieldsValid,
              next: isAllFieldsValid,
            }"
            class="btn me-3 mt-4 d-flex align-items-center"
          >
            <span class="material-symbols-rounded me-2">arrow_forward</span
            >{{ "Next" }}
          </button>
        </div>

        <b-modal
          :id="'cancel'"
          custom-class="delete-modal"
          hide-header
          hide-footer
          centered
        >
          <div class="delete-prompt">
            <p><b>Are you sure you want to delete this entry?</b><br /></p>
            <p class="bg">This action cannot be undone.</p>
          </div>
          <div class="delete-prompt-options">
            <button class="btn-outlined" @click="cancel">Cancel</button>
            <button class="btn-danger" @click="deleteEntry">Delete</button>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/misc/BreadCrumb.vue";
import JurisdictionAndCourt from "./pre_trial/JurisdictionAndCourt.vue";
// import LegalTeamAndJudiciary from './pre_trial/LegalTeamAndJudiciary.vue';
import FactsOfTheCase from "./pre_trial/FactsOfTheCase.vue";
import WitnessInput from "./pre_trial/WitnessInput.vue";
import EvidenceInput from "./pre_trial/EvidenceInput.vue";
import LegalExpertSystem from "@/store/LEGACY/LES.js";
export default {
  components: {
    BreadCrumb,
    JurisdictionAndCourt,
    // LegalTeamAndJudiciary,
    FactsOfTheCase,
    WitnessInput,
    EvidenceInput,
  },

  data() {
    return {
      isSubmitting: false,
      shouldReset: false,
      clientCase: "",
      clientType: "",
      // Team
      legalTeam: {},
      judiciary: {},

      // Main Jurisdiction and Court
      jurisdiction: {},
      // fact of the case / case info / pre event
      // [0] valid/not valid | [1] actual input
      pre_event: [false, null],

      // client witness & adverse witness (opposing)
      // [0] saved/not saved | [1] saved inputs
      c_wit: [true, []],
      a_wit: [true, []],

      // client evidence & adverse evidence
      // this work same as the witness inputs
      c_evid: [true, []],
      a_evid: [true, []],
      refreshKey: 0,
    };
  },

  watch: {
    pre_event: {
      handler(newVal) {
        var valid = newVal[1].length > 0;
        try {
          valid =
            valid &&
            newVal[1].map((f) => f?.valid ?? false).filter((v) => !v).length ===
              0;
        } catch {
          valid = newVal[1].trim().length > 0;
        }

        if (valid !== newVal[0]) this.$set(this.pre_event, 0, valid);
      },
      deep: true,
    },
  },
  computed: {
    getCharCount() {
      return this.clientCase.length;
    },
    isAllFieldsValid() {
      const hasValidJurisdiction = () => {
        if (
          this.jurisdiction &&
          typeof this.jurisdiction === "object" &&
          Object.keys(this.jurisdiction).length > 0
        ) {
          return Object.values(this.jurisdiction).some((array) => {
            if (Array.isArray(array)) {
              const isValidPartyObjects = array.every(
                (item) =>
                  typeof item === "object" &&
                  item !== null &&
                  "name" in item &&
                  "id" in item
              );

              if (isValidPartyObjects) {
                return true;
              }

              return array.some(
                (item) => typeof item === "string" && item === "all"
              );
            }
            return false;
          });
        }
        return false;
      };

      return (
        this.clientCase.trim() &&
        this.clientType.trim() &&
        hasValidJurisdiction() &&
        this.pre_event[0] &&
        this.pre_event[1]
      );
    },
  },
  methods: {
    hideAllDropdown() {
      this.$root.$emit("bv::hide::tooltip");
    },
    JurisdictionCourts(updatedJurisdiction) {
      this.jurisdiction = updatedJurisdiction;
    },
    toastError(flag) {
      this.$toast.error(
        {
          empty: "Please fill in all required fields",
          max: "Maximum word limit exceeded",
          missing: "Please fill in all fields for each witness",
          pre_event:
            "Please upload file OR input text for Background/Facts of The Case",
          courts: "Please select court(s) for each chosen jurisdiction",
          file: "Please upload a .pdf or .docx file",
          evidence: "Please upload .pdf, .png, .jpeg or .jpg files",
          "max-evidence": "Maximum evidence limit (10) exceeded",
        }[flag]
      );
    },
    parseCollectionValue(original) {
      return JSON.stringify(
        original.reduce((acc, obj) => {
          acc[obj.name] = obj.statement;
          return acc;
        }, {})
      );
    },
    cancel() {
      this.$bvModal.hide("cancel");
    },
    deleteEntry() {
      this.shouldReset = true;
      this.clientCase = "";
      this.clientType = "";
      this.legalTeam = {};
      this.judiciary = {};
      this.jurisdiction = {};
      this.$nextTick(() => {
        this.shouldReset = false;
      });
      this.$bvModal.hide("cancel");
      this.$router.go(-1);
    },
    async sendValue() {
      this.isSubmitting = true;
      this.$validator.validateAll();
      const countryMapping = {
        AUS: "Australia",
        USA: "USA",
        CAN: "Canada",
        NZ: "New Zealand",
        SG: "Singapore",
        MY: "Malaysia",
        UK: "United Kingdom",
      };
      const j = {};
      for (const country in this.jurisdiction) {
        if (Object.prototype.hasOwnProperty.call(this.jurisdiction, country)) {
          const countryName = countryMapping[country] || country; // Use the mapped name or fallback to original
          j[countryName] = this.jurisdiction[country].map(
            (court) => court.id ?? "all"
          );
        }
      }
      const juriC = JSON.stringify(j);

      // Prepare the submission data object
      const submissionData = new FormData();
      submissionData.append("client_case", this.clientCase);
      submissionData.append("client_type", this.clientType);
      submissionData.append("jurisdiction", juriC);
      submissionData.append("language", "en");
      submissionData.append("location_id", 1);

      // Process the facts of the case
      if (typeof this.pre_event[1] === "string") {
        submissionData.pre_event = this.pre_event[1];
        submissionData.append("pre_event", this.pre_event[1]);
      } else {
        submissionData.append("pre_event_file", this.pre_event[1][0]["file"]);
      }

      // Process the judiciary data
      if (Array.isArray(this.judiciary) && this.judiciary.length > 0) {
        const judge = this.judiciary.map((judge) => ({
          first_name: judge.first_name,
          last_name: judge.last_name,
          type: judge.type,
          title: judge.title,
        }));
        // For multiple judges
        // for (let i = 0; i < judge.length; i++) {
        // submissionData.append('judge', JSON.stringify(judge[i]));
        // }
        submissionData.append("judge", JSON.stringify(judge[0]));
      }

      // Process the legal team data
      if (Array.isArray(this.legalTeam) && this.legalTeam.length > 0) {
        const lawyer = this.legalTeam.map((lawyer) => ({
          first_name: lawyer.first_name,
          last_name: lawyer.last_name,
          type: lawyer.type,
          title: lawyer.title,
        }));
        // For multiple lawyers
        // for (let i = 0; i < lawyer.length; i++) {
        //   submissionData.append('lawyer', JSON.stringify(lawyer[i]));
        // }
        submissionData.append("lawyer", JSON.stringify(lawyer[0]));
      }

      // Process client witnesses
      if (this.c_wit && this.c_wit[1].length) {
        if (
          this.c_wit[1].every(
            (witness) =>
              typeof witness.name === "string" &&
              typeof witness.statement === "string"
          )
        ) {
          const witness_client = this.c_wit[1].map((witness) =>
            JSON.stringify({ [witness.name]: witness.statement })
          );
          for (let i = 0; i < witness_client.length; i++) {
            submissionData.append("witness_client[]", witness_client[i]);
          }
        } else {
          const witness_client_file = this.c_wit[1].map(
            (evidence) => evidence.file
          );
          for (let i = 0; i < witness_client_file.length; i++) {
            submissionData.append(
              "witness_client_file[]",
              witness_client_file[i]
            );
          }
        }
      }

      // Process opposing witnesses
      if (this.a_wit && this.a_wit[1].length) {
        if (
          this.a_wit[1].every(
            (witness) =>
              typeof witness.name === "string" &&
              typeof witness.statement === "string"
          )
        ) {
          const witness_opposing = this.a_wit[1].map((witness) =>
            JSON.stringify({ [witness.name]: witness.statement })
          );
          for (let i = 0; i < witness_opposing.length; i++) {
            submissionData.append("witness_opposing[]", witness_opposing[i]);
          }
        } else {
          const witness_opposing_file = this.a_wit[1].map(
            (evidence) => evidence.file
          );
          for (let i = 0; i < witness_opposing_file.length; i++) {
            submissionData.append(
              "witness_opposing_file[]",
              witness_opposing_file[i]
            );
          }
        }
      }

      // Process client evidence
      if (this.c_evid && this.c_evid[1].length) {
        if (
          this.c_evid[1].every(
            (evidence) => typeof evidence.statement === "string"
          )
        ) {
          const evidence_client = this.c_evid[1].map(
            (evidence) => evidence.statement
          );
          for (let i = 0; i < evidence_client.length; i++) {
            submissionData.append("evidence_client[]", evidence_client[i]);
          }
        } else {
          const evidence_client_file = this.c_evid[1].map(
            (evidence) => evidence.file
          );
          for (let i = 0; i < evidence_client_file.length; i++) {
            submissionData.append(
              "evidence_client_file[]",
              evidence_client_file[i]
            );
          }
        }
      }

      // Process opposing evidence
      if (this.a_evid && this.a_evid[1].length) {
        if (
          this.a_evid[1].every(
            (evidence) => typeof evidence.statement === "string"
          )
        ) {
          const evidence_opposing = this.a_evid[1].map(
            (evidence) => evidence.statement
          );
          for (let i = 0; i < evidence_opposing.length; i++) {
            submissionData.append("evidence_opposing[]", evidence_opposing[i]);
          }
        } else {
          const evidence_opposing_file = this.a_evid[1].map(
            (evidence) => evidence.file
          );
          for (let i = 0; i < evidence_opposing_file.length; i++) {
            submissionData.append(
              "evidence_opposing_file[]",
              evidence_opposing_file[i]
            );
          }
        }
      }

      for (let pair of submissionData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }

      try {
        const response = await LegalExpertSystem.LesCreatePreTrialFormData(
          submissionData
        );
        if (response && response.data && response.data.data?.id) {
          this.$toast.success("Successfully submitted!");
          this.$router.push({
            name: "LegalCopilot_CaseFlowResult",
            params: {
              id: response.data.data?.id,
              category: "preTrial",
            },
          });
        }
      } catch (error) {
        console.log(error);
        this.$toast.error("An error occurred. Please try again.");
      }
    },
  },
};
</script>

<style scoped>
.form-control {
  font-size: 14px;
}

h3,
p {
  margin-bottom: 0px;
}

#Header {
  margin: -20px -20px 0px -20px;
  padding: 16px 20px;
  height: 72px;
  display: flex;
  align-items: center;
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.add {
  background-color: var(--mid-grey);
  border-radius: 50px;
}

/* Client */
.client_title {
  color: var(--neutral-black) !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}

.client_card {
  background: var(--interface-grey);
  height: 96px;
  border-radius: 8px;
  border: var(--light-grey);
  padding-top: 1rem;
  resize: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.gw {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.radio {
  width: 20px;
  height: 20px;
}

.border-btm {
  border-bottom: 1px solid var(--mid-grey);
}

.btn {
  padding: 4px 16px;
  border-radius: 4px;
  height: 32px;
}

.cancel {
  color: var(--dark-blue) !important;
  border: 1px solid var(--mid-grey);
}

.cancel:hover {
  color: var(--dark-blue);
}

.next-disabled {
  width: 100px;
  background: var(--light-grey);
  color: var(--mid-grey);
}

.next {
  width: 100px;
  background: var(--primary);
  color: #fff;
}

.arrow {
  width: 24px;
  height: 24px;
}

.border_error {
  outline: 1px solid var(--status-red);
  width: 824px;
  height: 140px;
  border-radius: 8px;
  padding: 4px;
}

.background_error {
  background-color: #ff4d4f1a;
}

.type_error {
  width: 420px;
  height: 33px;
  padding: 4px;
  border-radius: 8px;
  outline: 1px solid var(--status-red);
}

.sm {
  color: var(--status-red);
}

#Error {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .icon_info {
    vertical-align: middle;
    font-size: 18px;
    color: var(--neutral-black);
    font-variation-settings: "FILL" 1;
  }
}

p {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
</style>
<style scoped>
.delete-prompt {
  margin: 8px 8px 20px 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-prompt p {
  margin-bottom: 0px;
  text-align: center;
}

.delete-prompt p b {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--neutral-black);
}

.delete-prompt .bg {
  color: var(--dark-grey) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.delete-prompt-options {
  display: flex;
  justify-content: space-between;
  margin: 0px 8px 8px 8px;
}

.delete-prompt-options button {
  width: calc(50% - 4px);
  height: 32px;
}
</style>
<style scoped>
.custom-radio-wrapper {
  display: flex;
  align-items: center;
}

.custom-radio-input {
  position: absolute;
  opacity: 0;
}

.custom-radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
}

.custom-radio-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--dark-grey);
}

.custom-radio-input:checked + .custom-radio-label .custom-radio-circle {
  background-color: var(--light-blue);
  border-color: var(--primary);
}

.custom-radio-circle::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary);
  transform: scale(0);
  transition: transform 0.3s;
}

.custom-radio-input:checked + .custom-radio-label .custom-radio-circle::after {
  transform: scale(1);
}

.custom-radio-text {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--dark-grey);
}

.custom-radio-input:checked + .custom-radio-label .custom-radio-text {
  color: var(--primary);
}
</style>
